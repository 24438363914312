import React from 'react'
import NewsCard from '../../components/news-card'
import styles from './home-news.module.scss'

const HomeNews = () => {
    return (
        <div className={styles.newsWrapper}>
            <div className="container">
                <h4 className={styles.h4}>Latest News:</h4>
                <div className={styles.news}>
                    <div>
                        <NewsCard
                            date="Sept 29th, 2020"
                            url="https://www.medicalcountermeasures.gov/newsroom/2020/invirsa/"
                            title="BARDA partners with Invirsa to develop a treatment for ocular conditions associated with DNA damage, including sulfur mustard injury"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeNews